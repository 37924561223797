import React from 'react'
// import { Row,Col } from 'react-bootstrap';
import Careersdata from "./jsons/careersdata";
import { Link } from 'react-router-dom';



function createcareer(Careerdata){
    return( 
        <div>
        <h5>
            Please mail your Resume to Sanspro at<br/>careers@sanspro.com <br/>Click here.!<br/>
            <a href="mailto:careers@sanspro.com"><i style={{color:"#6597D7"}} className="foot-img fa-solid fa-envelope"></i></a>
        </h5>

        <div className='con'>
            <div className='concard'>
                <div className='conbox'>
                    <div className='concontent'>
                        <h3>{Careerdata.jobtitle}</h3>
                        <p><p style={{fontSize:"20px",marginBottom:"10px"}}>Requirements:</p>{Careerdata.requirements}</p>
                        <p><p style={{fontSize:"20px",marginBottom:"10px"}}>Responsibilities:</p>{Careerdata.responsibilities}</p> 
                        <p><p style={{fontSize:"20px",marginBottom:"10px"}}>Qualifications:</p>{Careerdata.qualifications}</p>
                        <p><p style={{fontSize:"20px",marginBottom:"10px"}}>Company Information:</p>{Careerdata.companyinformation}</p>
                        <p><p style={{fontSize:"20px",marginBottom:"10px"}}>Location:</p>{Careerdata.location}</p>
                        <Link exact to={'/careers/apply'}>Apply now</Link> 
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}


const careercomponent = () => {
  return (
    
    <div className='makeitcenter'>
    <h1 style={{textAlign:"left"}}>Careers</h1>

       { Careersdata.map(createcareer)}
    
    </div>    
    
  )
}

export default careercomponent;