import page from "../../images/page1.jpg";
import cart from "../../images/yourcart.jpg";

const announcements=
[
    {
    "id" : 0,
    "img" :[ {"id": "00","imglink": "https://res.cloudinary.com/buznear/image/upload/v1692341595/web/images/Buznear_Logo.png"}],
    "des" : "Welcome to BUZNEAR ! Your one stop online marketplace for all your needs."

},
{
    "id" : 1,
    "img" :[{"id": "10","imglink": "https://res.cloudinary.com/buznear/image/upload/v1690542288/web/images/pamphlet-01.jpg"}],
    "des" : "Shop from a wide range of products and services from your favourite local stores."

},{
    "id" : 2,
    "img" :[{"id": "20","imglink": cart }],
    "des" : "We are live now! Get your products delivered to your doorstep."

},

{
    "id" : 3,
    "img" :[{"id": "20","imglink":page }],
    "des" : "Join our dynamic delivery partner community and unlock a world of flexible earning opportunities."
}

]

export default announcements;