import React from "react";
import Home from "./components/Home";
import Navigation from "./components/navbar";
import Footer from "./components/footer";
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import AppContact from './components/AppContact';
import Careers from "./components/careers";
import CareerApplication from "./components/careerapplication";

const App = () => {
  return (
    <div>
        
        <BrowserRouter>
        <Navigation></Navigation>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<AppContact/>} />
        <Route path="/careers" element={<Careers/>} />
        <Route path="/careers/apply" element={<CareerApplication/>}/>
        </Routes>
        </BrowserRouter>
        <Footer></Footer>
    </div>
  )
}

export default App;