import styled from "styled-components";

const Foot=styled.div`
text-align: center;

`;

let Footer=()=>{
    return(
        <Foot >
        <footer id="footer">
        <div className="foot">
        <a href="https://www.linkedin.com/company/sanspro/?originalSubdomain=in"><i className="foot-img fa-brands fa-twitter"></i></a>
        <a href="https://www.linkedin.com/company/sanspro/?originalSubdomain=in"><i className="foot-img fa-brands fa-linkedin"></i></a>
        <a href="https://www.linkedin.com/company/sanspro/?originalSubdomain=in"><i className="foot-img fa-brands fa-instagram"></i></a>
        <a href="mailto:contact@sanspro.com"><i className="foot-img fa-solid fa-envelope"></i></a>
        
        <p>© 2023 All rights reserved. Sanspro™ Technologies Pvt Ltd </p>
        </div>
   
      </footer>
      </Foot>
    )
}

export default Footer;