import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import About from './about';
import Announcements from './announcements';
import styled from 'styled-components';
import Productcorousal from './productcorousal';
import Productdownload from './productdownload';
import Deliverycorousal from './deliverycorousal';
import DeliveryProductDownload from './deliveryappdownload';
import '../App.css';


const OurProducts=styled.div`
    padding: 2%;
    font-family: 'Merriweather', serif;
    margin:4% 1%;
    
`;



const AboutAnnounce=styled.div`
/* display: flex; */
margin:2% 1% 1% 1%;

padding: 0%;

background-color: white;
`;




const Home=()=> {
  return (
    <div>
      
      <Container>
      <AboutAnnounce>
      <Row >
      <Col lg={8} xs={12}>
      <About></About>
      </Col>
      <Col lg={4} xs={12}>
      <Announcements></Announcements>
      </Col>
      </Row>
      </AboutAnnounce>
      </Container>

      <Container>
      <OurProducts className="shadow-lg Product" id="products" >
        <h1 style={{textAlign:"left"}}>Our Products</h1>
        <br/>
        <h1 style={{textAlign:"center"}}>B U Z N E A R ™</h1>
        <br/>
        <Row>
        <Col lg={6} xs={12}>
        <Productcorousal></Productcorousal>
        </Col>
        <Col lg={6} xs={12}>
        <Productdownload></Productdownload>
        </Col>
        </Row>
        <br/>
        <br/>
        <br/>
        <h1 style={{textAlign:"center"}}>Buznear Delivery</h1>
        <br/>
        <Row>
        <Col lg={6} xs={12}>
        <DeliveryProductDownload></DeliveryProductDownload>
        </Col>
        <Col lg={6} xs={12}>
        <Deliverycorousal></Deliverycorousal>
        </Col>
        </Row>
      </OurProducts>
      </Container>

      
    </div>
  );
}

export default Home;
