import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styled from "styled-components";
import page1 from '../images/page1.jpg';
import page2 from '../images/page2.jpg';
import page3 from '../images/page3.jpg';
import page4 from '../images/page4.jpg';
import page5 from '../images/page5.jpg';


const ImgCarousel=styled.div`

    border-radius: 5%;
    
    /* padding: 2%; */
    
    justify-content: center;
    
    margin: 1%;

    
    
`;


const deliverycorousal = () => {
  return (
    <ImgCarousel>
                <Carousel variant="dark" indicators="false">
                    <Carousel.Item>
                    <img src={page1} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={page2} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={page3} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={page4} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={page5} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>        
                </Carousel>
                <p style={{margin:"5% 5% 0% 5%"}}>More interesting features are on the way!</p>
            </ImgCarousel>
  )
}

export default deliverycorousal;