import React from 'react';
import styled from 'styled-components';


const Addressdiv=styled.div`
/* display:"flex"; */
margin: 5% 5% 5% 5%;
font-family: 'Merriweather', serif;
text-align: center;
padding: 3% 5% ;
@media (max-width: 768px) {
      margin:20% 5% 5% 5%;
      height: auto;
  }
`

const Address=()=>{
    return(
    <Addressdiv className='shadow-lg'>
      <h2>Our Location</h2>
      <p style={{fontWeight:"bold",margin:"2%"}}>1-103/1/B/6, Sai brundhavan colony <br/>Serilingampally, Hyderabad, Telangana - 500019 <br/>India <br/>Ph: +91 9059287515<br/>
      </p>
      <p style={{fontWeight:"bold"}}>Prospect Clients? please call/Whatsapp on: Ph: +91 9059287515</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.486286320008!2d78.31409921486349!3d17.484285088020645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb933095d7dbef%3A0xc72311650099e736!2sSanspro%20%EF%B8%8F%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1676458459317!5m2!1sen!2sin" width="300" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border:"0",margin:"2%"}} title="SansLocation"></iframe>
    </Addressdiv>)
  }

export default Address;