import React,{ useState } from 'react';
import styled from 'styled-components';
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';





const Formdiv=styled.div`
padding: 5%;
margin: 3% auto;

`;


const Phone=()=>{
  const [value, setValue] = useState()
  return (
  <div>
    <PhoneInput id="phone_number" defaultCountry='IN'
      placeholder="Enter phone number"
      
      value={value}
      onChange={ value=>setValue(value)
      }
      />
    {
      value && isValidPhoneNumber(value) ? <span>&#9989;</span> :""
      }
      </div>
  )
}


const Form=()=>{
  const history = useNavigate();

  const [user,setUser]= useState({
    firstname: "", lastname:"", email:"", phonenumber:"",resume:""
  });
  
  let name,value;

const handleInputs=(e) =>{
    console.log(e.target.value);
    name=e.target.name;
    value=e.target.value;

    setUser({...user, [name]:value});
}


const PostData = async(e) =>{
  e.preventDefault();
  const {firstname, lastname, email, phonenumber,resume}=user;
  const res = await fetch("/register",{
    method:"POST",
    headers:{
      "Content-Type" : "application/json"
    },
    body:JSON.stringify({
      firstname, lastname, email, phonenumber,resume
    })
  });

  const data = await res.json();
  if(data.status === 422 || !data){
    window.alert("Invalid Message");
  }
  else{
    window.alert("Message Sent !");
    history.push("/contact");
  }



}


    return(

      <Formdiv className='shadow-lg'>
        
      <form className="contact">
    
      <h1 className="h3 mb-3 fw-normal">Sanspro Technologies</h1>

      <div className="form-floating">
        <input type="text" className="form-control" id="firstname" name="firstname" autoComplete="off" placeholder="First name" value={user.firstname}
          onChange={handleInputs}
        />
        <label htmlFor="firstname">First Name</label>
      </div>
      <div className="form-floating mt-4">
        <input type="text" className="form-control" id="lastname" name="lastname" autoComplete="off" placeholder="Last name" value={user.lastname}
          onChange={handleInputs}
        />
        <label htmlFor="lastname">Last Name</label>
      </div>
      <div className="form-floating mt-4">
        <input type="email" className="form-control" id="email" name="email" autoComplete="off" placeholder="Email" required  value={user.email}
          onChange={handleInputs}
        />
      <label htmlFor="floatingEmail" required>Email</label>
      </div>
      <div className='mt-4'>
        <Phone name="phonenumber" value={user.phonenumber}
        onChange={handleInputs}/>
      </div>
      <div className='mt-3'>
        <label style={{display:"block"}} htmlFor="resume">Resume:</label>

        <input type="file" style={{width:"100%"}} required/>
      </div>
      <button className="w-100 btn btn-lg btn-primary mt-4" type="submit" onClick={PostData} disabled>Send</button>

      </form>
      </Formdiv>
    );
}



const careerapplication=()=> {
    return (
        
        <div  style={{display:"flex"}}>
        
        <Form></Form>
        
        </div>
        
    );
}


export default careerapplication;