import React from "react";
import styled from "styled-components";


import buztag from '../images/delivery_logo.png';
import google_play from '../images/google-play-badge.png';
import apple_play from '../images/app-store-badge.png';


const BuznearDownload=styled.div`
    text-align: center;
    font-size: larger;
    justify-content: center;
    margin: 15% 1%;
    

`

const DeliveryProductDownload=()=>{
    return(
            <BuznearDownload>
                <img src={buztag} alt="img" className="buztag"/>
                <p style={{padding:"5%"}}>
                Are you looking for flexible work with great earning potential? Look no further! Buznear Delivery is the ultimate platform for delivery partners who want to be part of an exciting and growing community. Whether you're a student, a part-time worker, or simply seeking extra income, you can join us.
        </p>
        <h2>Download the app now!</h2>
        <div className="down_div">
        <a href="https://play.google.com/store/apps/details?id=com.buznear.delivery"><img src={google_play} alt="img" className="down"></img></a>

        <a href="https://apps.apple.com/in/app/buznear-delivery/id6462658848"><img src={apple_play} alt="img" className="down"></img></a>
        </div>
        </BuznearDownload>
    )
}

export default DeliveryProductDownload;