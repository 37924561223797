import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import data from './jsons/announcements';
import { ImageGroup } from 'react-fullscreen-image'
// import $ from 'jquery';



const AnnounceDiv=styled.div`
padding: 3%;
margin: 4% 1% 2% 0%;
/* height: 95%; */
width: 100%;
font-family: 'Merriweather', serif;
text-align: center;
`;



function createCard(announcement){
    return( 
        <Carousel.Item style={{height:"380px"}}>
        
        <div>
        <ImageGroup>
               {
                announcement.img.map(image=>(
                    
                    <img src={image.imglink} alt="img"
                    className="announcement_img data-enlargable"
                    style={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        padding:'10% 15% 5% 15%',
                        objectFit: 'contain',
                    }} />
              
              ))
               }
        </ImageGroup>
             
                
            <Card.Body className='announce_des'>
                {announcement.des}
            </Card.Body>
            
            
        </div>
        </Carousel.Item>
    )
}



let Announcements=()=>{
    return(
        <AnnounceDiv className="shadow-lg">
        <h1>Announcements</h1>
        <Carousel style={{display:"flex"}}>

        {
            data.map(createCard)
        }
        
        </Carousel>
        </AnnounceDiv>
    )
}

export default Announcements;