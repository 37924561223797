import React from "react";
import styled from "styled-components";

const Container=styled.div`
padding: 3%;
margin: 2% 1% 2% 0%;
height: 95%;
font-family: 'Merriweather', serif;

/* @media (max-width: 768px) {
      margin:25% 1% 5% 0%;
      height: auto;
  } */
  
`;


let About=()=>{
    return(
        <Container className="shadow-lg" id="about">
            <h1>About Us</h1>
            <p>Sanspro Technologies Pvt. Ltd. is an E-Commerce & Product based information technology services company serving global markets. Sanspro Technologies single minded mission is to transform our customer's business vision into reality through superior, cost-effective and Quality Engineering Solutions. With development centers in India, Sanspro offers a unique combination of R&D, Product Engineering & Software Development Services. Sanspro team works closely with its customers in providing the solutions that meet their strategic objec tives.
            <br/>
            <br/>

            Our products are designed to be intuitive, easy to use, and fun to operate. We are committed to providing our customers with products that are not only innovative, but also safe and reliable. We are constantly striving to improve our products and services to better meet the needs of our customers.
            </p>
        </Container>
    )
}

export default About;