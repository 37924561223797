import React from "react";
import styled from "styled-components";


import buztag from '../images/buznear_tag.png';
import google_play from '../images/google-play-badge.png';
import apple_play from '../images/app-store-badge.png';


const BuznearDownload=styled.div`
    text-align: center;
    font-size: larger;
    justify-content: center;
    margin: 15% 1%;
    

`

const ProductDownload=()=>{
    return(
            <BuznearDownload>
                <img src={buztag} alt="img" className="buztag"/>
                <p style={{padding:"5%"}}>
          Buznear is a unique online social multi-platform marketplace application which helps hyperlocal to nationwide small to big sellers or service providers to sell and manage their catalogs hassle-free, without spending money to build their own E-commerce website or online application.
        </p>
        <h2>Download the app now!</h2>
        <div className="down_div">
        <a href="https://play.google.com/store/apps/details?id=com.buznear"><img src={google_play} alt="img" className="down"></img></a>

        <a href="https://apps.apple.com/app/buznear/id1670086859"><img src={apple_play} alt="img" className="down"></img></a>
        </div>
        </BuznearDownload>
    )
}

export default ProductDownload;