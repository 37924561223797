import React from "react";

import { Link } from 'react-router-dom';


import logo from "./../images/SANSPRO.png"



let Navigation=()=>{
    return(

      <div style={{padding:"0.5% 5%"}} className="shadow-sm">
        <nav className="navbar navbar-expand-lg" >
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <Link className="navbar-brand" to="/"><img src={logo} alt="logo" style={{width:"20%"}}></img></Link>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        
      </ul>
      <div className="d-flex">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li style={{listStyleType:"none"}} className="nav-item">
            <Link className="nav-link" exact to='/#about' href="/#about">HOME</Link>
          </li>
          {/* <li style={{listStyleType:"none"}} className="nav-item">
            <Link className="nav-link" exact to='/#products'>PRODUCTS</Link>
          </li> */}
          <li style={{listStyleType:"none"}} className="nav-item">
            <Link className="nav-link" exact to='/careers'>CAREERS</Link>
          </li>
          <li style={{listStyleType:"none"}} className="nav-item">
            <Link className="nav-link" exact to='/contact'>CONTACT</Link>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>
    </div>


    //     <div>
    //       <Navbar fixed="top shadow-sm" collapseOnSelect expand="lg" bg="white" variant="light">
    //   <Container>
    //     <Navbar.Brand href="/"><img src={logo} style={{width:"20%"}} alt="logo"/>
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="me-auto">
    //         <Nav.Link variant="pills" exact href="/#about" className="main-nav" activeClassName="main-nav-active">About</Nav.Link>
    //         <Nav.Link exact to="/#products" href="/#products" className="main-nav" activeClassName="main-nav-active">Products</Nav.Link>
    //         <Nav.Link exact to="/careers" href="/careers" className="main-nav" activeClassName="main-nav-active">Careers</Nav.Link>
    
    //         <Nav.Link exact to="/contact" href="/contact" className="main-nav" activeClassName="main-nav-active">Contact</Nav.Link>
    //       </Nav>
    //       <Nav>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>  
    //     </div>
    )
}

export default Navigation;