import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styled from "styled-components";
import mf from '../images/manage_favorite.jpg';
import bnu from '../images/buy_near_you.jpg';
import chat from '../images/chat.jpg';
import cs from '../images/create_store.jpg';
import em from '../images/easy_manage.jpg';
import ff from '../images/find_fast.jpg';
import fs from '../images/free_store.jpg';
import logo from '../images/logo.jpg';
import jobProfile from '../images/job_profile.jpg';
import yourcart from '../images/yourcart.jpg';
import payment from '../images/payment.jpg';



const ImgCarousel=styled.div`

    border-radius: 5%;
    
    /* padding: 2%; */
    
    justify-content: center;
    
    margin: 1%;

    
    
`;


const productcorousal = () => {
  return (
    <ImgCarousel>
                <Carousel variant="dark" indicators="false">
                    <Carousel.Item>
                    <img src={logo} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={fs} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={cs} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={bnu} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={ff} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={em} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={chat} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={mf} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={jobProfile} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={yourcart} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={payment} className="size d-block w-100 padtopandbottom" alt="img"/>
                    </Carousel.Item>
                    
                </Carousel>
            </ImgCarousel>
  )
}

export default productcorousal;