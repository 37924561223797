import React from 'react';
import styled from 'styled-components';
import Careercomponent from './careercomponent';

const CareerDiv=styled.div`
margin: 5% 5% 5% 5%;
font-family: 'Merriweather', serif;
text-align: center;
padding: 3% 5% ;

`



const Careers = () => {
  return (
    <CareerDiv className='shadow-lg'>

    {/* Job seekers?<br/> send your resume at: careers@sanspro.com */}
    
        <Careercomponent></Careercomponent>
    
    </CareerDiv>
  )
}

export default Careers;