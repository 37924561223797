const careersdata=
[
    {"id":1,
    "jobtitle":"Sales And Marketing Representative",
    "requirements":"Looking for Sales Executive who are ready to join immediately. Please note that, this is field work.",
    "companyinformation": "Product based software development company in Hyderabad",
    "location" : "Serilingampally, Hyderabad",
    "qualifications":"MBA / Any Graduate with minimum is preferable",
    "responsibilities":"Candidate should have good communication skills and explanation skills. Also should have marketing strategies. The responsible candidates have to register our product application with Shop Owners, Corporates, Showrooms etc. They have to work in field."
    }
]

export default careersdata;